<template>
  <div class="navbar-container d-flex content align-items-center">
    <b-modal
        hide-footer
        id="modal-prevent-closing1"
        ref="my-modal"
        :title="`Editar cita`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        size="xl"
    >

      <ModalAppointment v-if="id_apoi" :id_appoinment="id_apoi" />

    </b-modal>
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
        >
          <feather-icon
              icon="MenuIcon"
              size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block"/>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"

      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav" v-if="getUser.HrDepartment.name === 'CAJAS' || getUser.HrDepartment.name === 'FARMACIA'|| getUser.HrDepartment.name === 'ENFERMERIA' || getUser.HrDepartment.name === 'COORDINACION MEDICA'	">
            <p class="user-name font-weight-bolder mb-0" >
              <feather-icon
                  icon="FolderIcon"
                  size="21"
              /><span style="  border-radius: 50%;
  display: inline-block;background: #19112e;   height: 18px;
  width: 18px; color: white; text-align: center">{{getTotalTreatment.length}}</span>
            </p>
          </div>
        </template>
        <div style="overflow-y: auto;" >
          <b-dropdown-item    link-class="d-flex align-items-center"  v-for="(a, index) in getTotalTreatment" :key="index" @click="modalView(a.ClinicTreatment.AppointmentTreat.id)">
            <span>({{a.ClinicTreatment.AppointmentTreat.name}}) {{a.ClinicTreatment.AppointmentTreat.ResPartner.name}}</span>
          </b-dropdown-item>
        </div>

      </b-nav-item-dropdown>
      <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"

      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{getUser.name}}
            </p>
            <span class="user-status">   {{getUser.job_title}}</span>
          </div>
          <b-avatar
              size="40"
              variant="light-primary"
              badge
              :src="require('@/assets/images/avatars/13-small.png')"
              class="badge-minimal"
              badge-variant="success"
          />
        </template>
        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import {mapGetters,mapActions} from "vuex";
import {getMessaging, onMessage} from "firebase/messaging";
import {messaging} from "@/plugins/firebase";
import ModalAppointment from "@/components/Appointments/ModalAppointment";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import io from "socket.io-client";
import {urlSocket} from "@/sockets/socket";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    ModalAppointment,
    // Navbar Components
    DarkToggler,
  },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  data(){
    return{
      socket : io(urlSocket),
      total:[],
      id_apoi:null
    }
  },
  mounted() {
    const messaging = getMessaging();

    onMessage(messaging, async (payload)=>{
      console.log(messaging)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Nueva Notificacion",
          icon: "success",
          variant: "success",
        },
      });
      await this.countTreatment()

    })
    this.socket.on('LIST_TREATMENT', async(data) => {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Actualizacion en las citas',
          icon: 'EditIcon',
          variant: 'warning',
        },
      })
    });
  },
  async created() {

      if (this.getUser.HrDepartment.name === 'CAJAS' || this.getUser.HrDepartment.name === 'FARMACIA'|| this.getUser.HrDepartment.name === 'ENFERMERIA' || this.getUser.HrDepartment.name === 'COORDINACION MEDICA'){
        await this.countTreatment()
      }
  },
  methods:{
    ...mapActions('auth',['logout']),
    ...mapActions('treatment',['validateCount']),
    async countTreatment(){
      await this.validateCount()

    },
    resetModal() {
    },
    async modalView(id) {
      this.id_apoi = parseInt(id)
      await this.$bvModal.show('modal-prevent-closing1')
    },
  },
  computed:{
    ...mapGetters('auth',['getUser','getTokenTopic']),
    ...mapGetters('treatment',['getTotalTreatment'])
  }
}
</script>
<style scoped>
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  width: 100% !important;
}
</style>
